import React, { useContext, useEffect, useState } from "react";
import "./Home.scss";
import Dots from "../../svgIcons/Dots";
import Dotts from "../../svgIcons/Dotss";
import ImageToText from "../../svgIcons/ImageToText";
import WordToPdf from "../../svgIcons/WordToPdf";
import Pdf from "../../svgIcons/Pdf";
import Translation from "../../components/Translation/Translation";
import Footer from "../../components/Footer/Footer";
import Navigation from "../../components/Navbar/Navbar";
import InjectIntl from 'react-intl-inject';
import axios from "axios";
import { FormattedMessage } from 'react-intl';
import { API_BASE_URL } from "../../utils/globals";
import { userContext } from "../../store/context/user";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import VideoPlayer from "react-video-js-player";
import Logindots from "../../svgIcons/Logindots";

const faqs = [
  {
    question: <FormattedMessage id="home.faq1"></FormattedMessage>,
    answer: <FormattedMessage id="home.ans1"></FormattedMessage>,

  },
  {

    question: <FormattedMessage id="home.faq2"></FormattedMessage>,

    answer: <FormattedMessage id="home.ans2"></FormattedMessage>,
  },
  {
    question: <FormattedMessage id="home.faq3"></FormattedMessage>,

    answer: <FormattedMessage id="home.ans3"></FormattedMessage>,
  },
  /* {
    question: <FormattedMessage id="home.faq4"></FormattedMessage>,
 
    answer:<FormattedMessage id="home.ans4"></FormattedMessage>,
  },
  {
    question: <FormattedMessage id="home.faq5"></FormattedMessage>,
 
    answer:<FormattedMessage id="home.ans5"></FormattedMessage>,
  }, */
];

export default function Home(props) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const { state, dispatch } = useContext(userContext);
  const [faqIndex, setFaqIndex] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    if (!state.isLoggedIn)
      localStorage.removeItem("poshTranslateState");
    let arr = [];
    (async () => {
      //navigate to admin page if user is admin
      if (state && state?.user?.role == "admin") {
        navigate('admin/users')
      }
      //for first time users to get initial Values
      if (!state.user || state.user.role == "guest") {
        const guestUser = await axios.get(`${API_BASE_URL}/user/guest`);
        dispatch({ type: "GUEST_STATE", payload: guestUser.data });
      }
    })();
  }, []);
  return (
    <InjectIntl>
      {({ intl }) => (
        <>
          <div className="max-w-[1280px] mx-auto mt-20 " style={{ direction: (props.language == 'ar' || props.language == 'ur') ? 'rtl' : 'ltr' }}>
            <Navigation setLanguage={props.setLanguage} language={props.language} />
            <section className="flex items-center flex-col sm:flex-row">
              <div className="flex flex-1 p-4 flex-col px-3 my-10">
                <div className=" w-full text-center my-4 home">
                  {intl.formatMessage({ id: 'home.heading' })} <br></br>
                  {intl.formatMessage({ id: 'home.heading1' })}
                </div>
                <div className="text-[#363636] text-[27px] w-full text-center lang9 my-2">

                  {intl.formatMessage({ id: 'home.homesubHeading1' })} <span className="text-[#A92D62] font-[700px] lang9 "> <span className="text-[#A92D62] font-[700px] font-sans"> 200+ </span><b>{intl.formatMessage({ id: 'home.homesubHeading2' })}</b> </span>{intl.formatMessage({ id: 'home.homesubHeading3' })}
                  <br></br>

                  {intl.formatMessage({ id: 'home.homesubHeading4' })}

                </div>
              </div>
            </section>

            <div className="" >
              {isTabletOrMobile &&
                <div className="absolute -left-10 top-90 translationdots">
                  <Dots size={120} />
                </div>
              }
              {!isTabletOrMobile &&
                <div className="absolute -left-20 top-90">
                  <Dots size={120} />
                </div>
              }
              <div className="Translation">
                <Translation setLanguage={props.setLanguage} language={props.language} />
                {!isTabletOrMobile &&

                  <div className="absolute -right-0 top-100 mt-20">
                    <Dotts size={120} />
                  </div>
                }
              </div>
            </div>
            <section className="flex flex-col items-center py-20 relative overflow-hidden">
              <div className="supportHeading my-2">{intl.formatMessage({ id: 'home.supportHeading' })}</div>
              <div className="supportDescription ">
                {intl.formatMessage({ id: 'home.supportDescription' })}
              </div>
              <div className="flex flex-row p-4 flex-wrap justify-center pt-8">
                <Link to="/solutions">
                  <div className="card1 col-xl-3 col-lg-6">
                    <ImageToText size={80} className="docicon" />
                    <div className="ml-4   translationcard" style={{ margin: "10px" }}>
                      {intl.formatMessage({ id: 'home.imageToText' })}
                    </div>
                  </div>
                </Link>
                <Link to="/solutions">
                  <div className="card1 col-xl-3 col-lg-6">
                    <WordToPdf size={80} className="docicon" />
                    <div className="ml-4 translationcard " style={{ margin: "10px" }}>
                      {intl.formatMessage({ id: 'home.wordToPDF' })}
                    </div>
                  </div>
                </Link>
                <Link to="/solutions">
                  <div className="card1 col-xl-3 col-lg-6">
                    {/*  <img src="/assets/imgs/homepdf.png" /> */}
                    <Pdf size={80} className="docicon" />
                    <div className="ml-4  translationcard" style={{ margin: "10px" }}>
                      {intl.formatMessage({ id: 'home.pdfToWord' })}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="flex items-center  text-center flex-col  subDescription">
                {intl.formatMessage({ id: 'home.subDescription' })} <br></br>
                {intl.formatMessage({ id: 'home.subDescription1' })}
              </div>
            </section>
            <div className="absolute left-10 top-90 -mt-20">
              <img src="/assets/imgs/chaticon.png" className="homechaticon " />
            </div>
            <div className="absolute -left-20 top-90 -mt-20">
              <Dots size={120} />
            </div>
            {isTabletOrMobile &&
              <section className="flex   items-center flex-col sm:flex-row">
                <div className="grid-container">

                  <div className="grid-child ">
                    <div className="mb-2 aboutHeading px-10 items-center">
                      {intl.formatMessage({ id: 'home.aboutHeading' })}
                    </div>
                    <div className="text-[#048A8F] subHeading1 px-10 items-center">
                      {intl.formatMessage({ id: 'home.subHeading1' })}
                      <br />

                    </div>
                    <div className="subHeading2 px-10 items-center">
                      {intl.formatMessage({ id: 'home.subHeading2' })}
                      <br />
                      <br />
                    </div>
                  </div>

                  <div className="grid-child ">
                    <div className="relative w-fit h-fit ">
                      <img src="/assets/imgs/aboutus.png" alt="heroImage" className="max-auto" />
                    </div>
                  </div>

                </div>
              </section>
            }
            {!isTabletOrMobile &&
              <section className="flex px-4  items-center flex-col sm:flex-row">
                <div className="flex flex-1 flex-col">
                  <div className="mb-2 aboutHeading px-10 items-center">
                    {intl.formatMessage({ id: 'home.aboutHeading' })}
                  </div>
                  <div className="text-[#048A8F] subHeading1 px-10 items-center">
                    {intl.formatMessage({ id: 'home.subHeading1' })}
                    <br />

                  </div>
                  <div className="subHeading2 px-10 items-center">
                    {intl.formatMessage({ id: 'home.subHeading2' })}
                    <br />
                    <br />
                  </div>
                </div>
                <div className="flex flex-1 ">
                  <div className="relative w-fit h-fit">
                    <img src="/assets/imgs/aboutus.png" alt="heroImage" />
                  </div>
                </div>
              </section>
            }

            {isTabletOrMobile &&
              <section className="flex  px-2 items-center flex-col sm:flex-row py-10">
                <div className="grid-container">

                  <div className="grid-child ">
                    <div className="">
                      <div className="absolute right-0 top-90 faqdots">
                        <Dotts size={120} />
                      </div>
                      {/*    <div className="items-center justify-center">
                      <iframe allowtransparency="true" src="https://www.youtube.com/embed/E7wJTI-1dvQ" height="540px"> </iframe> </div> */}
                    </div>
                    <div className="items-center justify-center ">
                      <VideoPlayer
                        controls={true}
                        src="/assets/imgs/video.mp4"
                        className="VideoPlayer"

                      />
                    </div>
                  </div>

                  <div className="grid-child ">

                    <div className="row  justify-content-md-center">
                      <div className="col-md-7 col-sm-7  col-xs-7  ">
                        <p className="faq">   {intl.formatMessage({ id: 'home.faqtab' })} </p>
                        <p className="faqHeadding">   {intl.formatMessage({ id: 'home.faq' })}</p>
                      </div>
                      {!isTabletOrMobile &&
                        <div className="col-md-5 col-sm-5 col-xs-5 text-center ">
                          <img src="/assets/imgs/er.png" className="er text-center"
                          />
                        </div>
                      }
                    </div>
                    <div className="flex flex flex-col mt-2">
                      {faqs.map((faq, i) => (
                        <div
                          onClick={() => setFaqIndex(i)}
                          className="w-full flex flex-row justify-between px-1 border-zinc-200 mt-[-1px] hover:bg-[#FAFBFF] cursor-pointer"
                          key={i}>
                          <div className="row  ">
                            <span className="rounded-full bg-[#048A8FA6] text-[#FFFFFF] py-[6px] mt-6 random" >{i + 1}
                            </span>
                            <div className="">
                              <div className=" ml-10 mr-10  text-left faqquestions ">{faq.question}
                                <div className="text-left faqans mt-2">{faq.answer}</div>

                              </div>
                            </div>

                          </div>

                        </div>

                      ))}
                    </div>

                  </div>

                </div>
              </section>
            }

            {!isTabletOrMobile &&
              <section className="flex flex-row bg-[#ffffff] py-20" id="faq">
                <div className="container-fluid">
                  <div className="row flex  items-center  ">
                    <div className="col-md-6 col-sm-6 col-xs-6">
                      <div className="absolute -right-0 top-120">
                        <Dotts size={120} />
                      </div>

                      <div className="items-center justify-center py-10">


                        {/*   <iframe allowtransparency="true" src="https://www.youtube.com/embed/E7wJTI-1dvQ" height="540px"> </iframe> */}
                        <VideoPlayer
                          controls={true}
                          src="/assets/imgs/video.mp4"
                          className="VideoPlayer"


                        />

                        <div className="absolute left-10  videodots">
                          <Logindots size={110} />
                        </div>
                      </div>

                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-6">
                      <div className="row  justify-content-md-center">
                        <div className="col-md-7 col-sm-7  col-xs-7  ">
                          <p className="faq">   {intl.formatMessage({ id: 'home.faqtab' })} </p>
                          <p className="faqHeadding">   {intl.formatMessage({ id: 'home.faq' })}</p>
                        </div>
                        {!isTabletOrMobile &&
                          <div className="col-md-5 col-sm-5 col-xs-5 text-center ">
                            <img src="/assets/imgs/er.png" className="er text-center"
                            />
                          </div>
                        }
                      </div>
                      <div className="flex flex flex-col  mt-4">
                        {faqs.map((faq, i) => (
                          <div
                            onClick={() => setFaqIndex(i)}
                            className="w-full flex flex-row justify-between px-3 border-zinc-200 mt-[-1px] hover:bg-[#FAFBFF] cursor-pointer"
                            key={i}>
                            <div className="rounded-full bg-[#048A8FA6] justify-between py-[20px] text-[#FFFFFF] text-[20px] mt-6 random" >{i + 1}</div>
                            <div className="flex-1 flex-row">
                              <div className="ml-10 mr-10 mt-4  text-left faqquestions ">{faq.question}
                                <div className=" ml-2 text-left faqans">{faq.answer}</div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                </div>
              </section>
            }

            <div className="font-white text-[16px] text-center sm:text-left w-full mt-4">
              <button
                onClick={() => navigate('register')}
                className="getquote ">

                {intl.formatMessage({ id: 'home.getstart' })}

              </button>
            </div>
          </div>
          <Footer language={props.language} />
        </>
      )}</InjectIntl>
  );
}
