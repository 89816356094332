import React, { useCallback, useContext, useEffect, useState } from "react";
import { HiOutlineTranslate, HiOutlineDocumentText } from "react-icons/hi";
import { BsImage } from "react-icons/bs";
import { TbArrowsRightLeft } from "react-icons/tb";
import axios from "axios";
import { API_BASE_URL } from "../../utils/globals";
import DocumentTranslation from "./DocumentTranslation";
import TextTranslation from "./TextTranslation";
import ImageTranslation from "./ImageTranslation";
import { userContext } from "../../store/context/user";
import InjectIntl from 'react-intl-inject';
import { Link, useNavigate } from "react-router-dom";
import io from 'socket.io-client';

const socket = io(API_BASE_URL,{reconnect: true});
const Translation = (props) => {
  const [languageSelected, setAppLanguage] = useState('en');
  const [mode, setMode] = useState(0);
  const [languages, setLanguages] = useState([]);
  const [switchLang, setSwitchLang] = useState(false);
  const [updateLang, setUpdateLang] = useState(false);

  const [srcLang, setSrcLang] = useState(null);
  const [destLang, setDestLang] = useState(null);
  const [userPlan, setUserPlan] = useState(null);
  const { state, dispatch } = useContext(userContext);
  const [errorMsg, setErrorMsg] = useState(''); 
  const [fileProcessed, setFileProcessed] = useState(false);
  const [errorInProcessing, setErrorInProcessing] = useState(false);
  const [extractedText, setExtractedText] = useState('');
  const [changePercentage, setChangePercentage] = useState('');
  const navigate = useNavigate();
  let languagesList = [];

  
  const getlanguages = (langs) => {
    console.log("destLang 1", destLang);
    document.body.addEventListener("change", (e) => {
      if (e.target.matches("select")) {
          resizeSelect(e.target)
      }
    })
   
   
  console.log("destLang", destLang);

    langs.map(lang => {
      if (lang == 'en') {
        languagesList.push({
          name: 'English',
          id: 'eng_Latn',
        });
      }
      // else if (lang == 'ar') {
      //   languagesList.push({
      //     name: 'Arabic',
      //     id: 'ace_Arab',
      //   });
      // }
      // else if (lang == 'fr') {
      //   languagesList.push({
      //     name: 'French',
      //     id: 'fra_Latn',
      //   });
      // }
      // else if (lang == 'es') {
      //   languagesList.push({
      //     name: 'Spanish',
      //     id: 'spa_Latn',
      //   });
      // }
      // else if (lang == 'hi') {
      //   languagesList.push({
      //     name: 'Hindi',
      //     id: 'hin_Deva',
      //   });
      // }
      // else if (lang == 'ur') {
      //   languagesList.push({
      //     name: 'Urdu',
      //     id: 'urd_Arab',
      //   });
      // }
      // else if (lang == 'ja') {
      //   languagesList.push({
      //     name: 'Japanese',
      //     id: 'jpn_Jpan',
      //   });
      // }
      // else if (lang == 'zh') {
      //   languagesList.push({
      //     name: 'Chinese',
      //     id: 'zho_Hans',
      //   });

      // }
      // else if (lang == 'tr') {
      //   languagesList.push({
      //     name: 'Turkish',
      //     id: 'tur_Latn',
      //   });
      // }
  //  else if (lang == 'ar') {
  //       languagesList.push({
  //         name: 'Acehnese (Arabic script)',
  //         id: 'ace_Arab',
  //       });
  //     }
      // else if (lang == 'ace_Arab') {
      //   languagesList.push({
      //     name: 'Acehnese (Arabic script)',
      //     id: 'ace_Arab',
      //   });
      // }
      // else if (lang == 'ace_Latn') {
      //   languagesList.push({
      //     name: 'Acehnese (Latin script)',
      //     id: 'ace_Latn',
      //   });
      // }
      
      // if (lang =='Auto Detect') {  languagesList.push({ name:'Auto Detect',  id:'Auto Detect', }); } 
      if (lang =='ace_Arab') {  languagesList.push({ name:'Acehnese (Arabic script)',  id:'ace_Arab', }); } 
      else if (lang =='ace_Latn') {  languagesList.push({ name:'Acehnese (Latin script)',  id:'ace_Latn', }); } 
      else if (lang =='acm_Arab') {  languagesList.push({ name:'Mesopotamian Arabic',  id:'acm_Arab', }); } 
      else if (lang =='acq_Arab') {  languagesList.push({ name:'Ta’izzi-Adeni Arabic',  id:'acq_Arab', }); } 
      else if (lang =='aeb_Arab') {  languagesList.push({ name:'TunisianArabic',  id:'aeb_Arab', }); } 
      else if (lang =='afr_Latn') {  languagesList.push({ name:'Afrikaans',  id:'afr_Latn', }); } 
      else if (lang =='ajp_Arab') {  languagesList.push({ name:'South Levantine Arabic',  id:'ajp_Arab', }); } 
      else if (lang =='aka_Latn') {  languagesList.push({ name:'Akan',  id:'aka_Latn', }); } 
      else if (lang =='amh_Ethi') {  languagesList.push({ name:'Amharic',  id:'amh_Ethi', }); } 
      else if (lang =='apc_Arab') {  languagesList.push({ name:'North Levantine Arabic',  id:'apc_Arab', }); } 
      else if (lang =='arb_Arab') {  languagesList.push({ name:'Modern Standard Arabic',  id:'arb_Arab', }); } 
      else if (lang =='arb_Latn') {  languagesList.push({ name:'Modern Standard Arabic (Romanized)',  id:'arb_Latn', }); } //
      else if (lang =='ars_Arab') {  languagesList.push({ name:'Najdi Arabic',  id:'ars_Arab', }); } 
      else if (lang =='ary_Arab') {  languagesList.push({ name:'Moroccan Arabic',  id:'ary_Arab', }); } 
      else if (lang =='arz_Arab') {  languagesList.push({ name:'Egyptian Arabic',  id:'arz_Arab', }); } 
      else if (lang =='asm_Beng') {  languagesList.push({ name:'Assamese',  id:'asm_Beng', }); } 
      else if (lang =='ast_Latn') {  languagesList.push({ name:'Asturian',  id:'ast_Latn', }); } 
      else if (lang =='awa_Deva') {  languagesList.push({ name:'Awadhi',  id:'awa_Deva', }); } 
      else if (lang =='ayr_Latn') {  languagesList.push({ name:'Central Aymara',  id:'ayr_Latn', }); } 
      else if (lang =='azb_Arab') {  languagesList.push({ name:'South Azerbaijani',  id:'azb_Arab', }); } 
      else if (lang =='azj_Latn') {  languagesList.push({ name:'North Azerbaijani',  id:'azj_Latn', }); } 
      else if (lang =='bak_Cyrl') {  languagesList.push({ name:'Bashkir',  id:'bak_Cyrl', }); } 
      else if (lang =='bam_Latn') {  languagesList.push({ name:'Bambara',  id:'bam_Latn', }); } 
      else if (lang =='ban_Latn') {  languagesList.push({ name:'Balinese',  id:'ban_Latn', }); } 
      else if (lang =='bel_Cyrl') {  languagesList.push({ name:'Belarusian',  id:'bel_Cyrl', }); } 
      else if (lang =='bem_Latn') {  languagesList.push({ name:'Bemba',  id:'bem_Latn', }); } 
      else if (lang =='ben_Beng') {  languagesList.push({ name:'Bengali',  id:'ben_Beng', }); } 
      else if (lang =='bho_Deva') {  languagesList.push({ name:'Bhojpuri',  id:'bho_Deva', }); } 
      else if (lang =='bjn_Arab') {  languagesList.push({ name:'Banjar (Arabic script)',  id:'bjn_Arab', }); } 
      else if (lang =='bjn_Latn') {  languagesList.push({ name:'Banjar (Latin script)',  id:'bjn_Latn', }); } 
      else if (lang =='bod_Tibt') {  languagesList.push({ name:'Standard Tibetan',  id:'bod_Tibt', }); } 
      else if (lang =='bos_Latn') {  languagesList.push({ name:'Bosnian',  id:'bos_Latn', }); } 
      else if (lang =='bug_Latn') {  languagesList.push({ name:'Buginese',  id:'bug_Latn', }); } 
      else if (lang =='bul_Cyrl') {  languagesList.push({ name:'Bulgarian',  id:'bul_Cyrl', }); } 
      else if (lang =='cat_Latn') {  languagesList.push({ name:'Catalan',  id:'cat_Latn', }); } 
      else if (lang =='ceb_Latn') {  languagesList.push({ name:'Cebuano',  id:'ceb_Latn', }); } 
      else if (lang =='ces_Latn') {  languagesList.push({ name:'Czech',  id:'ces_Latn', }); } 
      else if (lang =='cjk_Latn') {  languagesList.push({ name:'Chokwe',  id:'cjk_Latn', }); } 
      else if (lang =='ckb_Arab') {  languagesList.push({ name:'Central Kurdish',  id:'ckb_Arab', }); } 
      else if (lang =='crh_Latn') {  languagesList.push({ name:'Crimean Tatar',  id:'crh_Latn', }); } 
      else if (lang =='cym_Latn') {  languagesList.push({ name:'Welsh',  id:'cym_Latn', }); } 
      else if (lang =='dan_Latn') {  languagesList.push({ name:'Danish',  id:'dan_Latn', }); } 
      else if (lang =='deu_Latn') {  languagesList.push({ name:'German',  id:'deu_Latn', }); } 
      else if (lang =='dik_Latn') {  languagesList.push({ name:'Southwestern Dinka',  id:'dik_Latn', }); } 
      else if (lang =='dyu_Latn') {  languagesList.push({ name:'Dyula',  id:'dyu_Latn', }); } 
      else if (lang =='dzo_Tibt') {  languagesList.push({ name:'Dzongkha',  id:'dzo_Tibt', }); } 
      else if (lang =='ell_Grek') {  languagesList.push({ name:'Greek',  id:'ell_Grek', }); } 
      else if (lang =='eng_Latn') {  languagesList.push({ name:'English',  id:'eng_Latn', }); } 
      else if (lang =='epo_Latn') {  languagesList.push({ name:'Esperanto',  id:'epo_Latn', }); } 
      else if (lang =='est_Latn') {  languagesList.push({ name:'Estonian',  id:'est_Latn', }); } 
      else if (lang =='eus_Latn') {  languagesList.push({ name:'Basque',  id:'eus_Latn', }); } 
      else if (lang =='ewe_Latn') {  languagesList.push({ name:'Ewe',  id:'ewe_Latn', }); } 
      else if (lang =='fao_Latn') {  languagesList.push({ name:'Faroese',  id:'fao_Latn', }); } 
      else if (lang =='fij_Latn') {  languagesList.push({ name:'Fijian',  id:'fij_Latn', }); } 
      else if (lang =='fin_Latn') {  languagesList.push({ name:'Finnish',  id:'fin_Latn', }); } 
      else if (lang =='fon_Latn') {  languagesList.push({ name:'Fon',  id:'fon_Latn', }); } 
      else if (lang =='fra_Latn') {  languagesList.push({ name:'French',  id:'fra_Latn', }); } 
      else if (lang =='fur_Latn') {  languagesList.push({ name:'Friulian',  id:'fur_Latn', }); } 
      else if (lang =='fuv_Latn') {  languagesList.push({ name:'Nigerian Fulfulde',  id:'fuv_Latn', }); } 
      else if (lang =='gla_Latn') {  languagesList.push({ name:'Scottish Gaelic',  id:'gla_Latn', }); } 
      else if (lang =='gle_Latn') {  languagesList.push({ name:'Irish',  id:'gle_Latn', }); } 
      else if (lang =='glg_Latn') {  languagesList.push({ name:'Galician',  id:'glg_Latn', }); } 
      else if (lang =='grn_Latn') {  languagesList.push({ name:'Guarani',  id:'grn_Latn', }); } 
      else if (lang =='guj_Gujr') {  languagesList.push({ name:'Gujarati',  id:'guj_Gujr', }); } 
      else if (lang =='hat_Latn') {  languagesList.push({ name:'Haitian Creole',  id:'hat_Latn', }); } 
      else if (lang =='hau_Latn') {  languagesList.push({ name:'Hausa',  id:'hau_Latn', }); } 
      else if (lang =='heb_Hebr') {  languagesList.push({ name:'Hebrew',  id:'heb_Hebr', }); } 
      else if (lang =='hin_Deva') {  languagesList.push({ name:'Hindi',  id:'hin_Deva', }); } 
      else if (lang =='hne_Deva') {  languagesList.push({ name:'Chhattisgarhi',  id:'hne_Deva', }); } 
      else if (lang =='hrv_Latn') {  languagesList.push({ name:'Croatian',  id:'hrv_Latn', }); } 
      else if (lang =='hun_Latn') {  languagesList.push({ name:'Hungarian',  id:'hun_Latn', }); } 
      else if (lang =='hye_Armn') {  languagesList.push({ name:'Armenian',  id:'hye_Armn', }); } 
      else if (lang =='ibo_Latn') {  languagesList.push({ name:'Igbo',  id:'ibo_Latn', }); } 
      else if (lang =='ilo_Latn') {  languagesList.push({ name:'Ilocano',  id:'ilo_Latn', }); } 
      else if (lang =='ind_Latn') {  languagesList.push({ name:'Indonesian',  id:'ind_Latn', }); } 
      else if (lang =='isl_Latn') {  languagesList.push({ name:'Icelandic',  id:'isl_Latn', }); } 
      else if (lang =='ita_Latn') {  languagesList.push({ name:'Italian',  id:'ita_Latn', }); } 
      else if (lang =='jav_Latn') {  languagesList.push({ name:'Javanese',  id:'jav_Latn', }); } 
      else if (lang =='jpn_Jpan') {  languagesList.push({ name:'Japanese',  id:'jpn_Jpan', }); } 
      else if (lang =='kab_Latn') {  languagesList.push({ name:'Kabyle',  id:'kab_Latn', }); } 
      else if (lang =='kac_Latn') {  languagesList.push({ name:'Jingpho',  id:'kac_Latn', }); } 
      else if (lang =='kam_Latn') {  languagesList.push({ name:'Kamba',  id:'kam_Latn', }); } 
      else if (lang =='kan_Knda') {  languagesList.push({ name:'Kannada',  id:'kan_Knda', }); } 
      else if (lang =='kas_Arab') {  languagesList.push({ name:'Kashmiri (Arabic script)',  id:'kas_Arab', }); } 
      else if (lang =='kas_Deva') {  languagesList.push({ name:'Kashmiri (Devanagari script)',  id:'kas_Deva', }); } 
      else if (lang =='kat_Geor') {  languagesList.push({ name:'Georgian',  id:'kat_Geor', }); } 
      else if (lang =='knc_Arab') {  languagesList.push({ name:'Central Kanuri (Arabic script)',  id:'knc_Arab', }); } 
      else if (lang =='knc_Latn') {  languagesList.push({ name:'Central Kanuri (Latin script)',  id:'knc_Latn', }); } 
      else if (lang =='kaz_Cyrl') {  languagesList.push({ name:'Kazakh',  id:'kaz_Cyrl', }); } 
      else if (lang =='kbp_Latn') {  languagesList.push({ name:'Kabiyè',  id:'kbp_Latn', }); } 
      else if (lang =='kea_Latn') {  languagesList.push({ name:'Kabuverdianu',  id:'kea_Latn', }); } 
      else if (lang =='khm_Khmr') {  languagesList.push({ name:'Khmer',  id:'khm_Khmr', }); } 
      else if (lang =='kik_Latn') {  languagesList.push({ name:'Kikuyu',  id:'kik_Latn', }); } 
      else if (lang =='kin_Latn') {  languagesList.push({ name:'Kinyarwanda',  id:'kin_Latn', }); } 
      else if (lang =='kir_Cyrl') {  languagesList.push({ name:'Kyrgyz',  id:'kir_Cyrl', }); } 
      else if (lang =='kmb_Latn') {  languagesList.push({ name:'Kimbundu',  id:'kmb_Latn', }); } 
      else if (lang =='kmr_Latn') {  languagesList.push({ name:'Northern Kurdish',  id:'kmr_Latn', }); } 
      else if (lang =='kon_Latn') {  languagesList.push({ name:'Kikongo',  id:'kon_Latn', }); } 
      else if (lang =='kor_Hang') {  languagesList.push({ name:'Korean',  id:'kor_Hang', }); } 
      else if (lang =='lao_Laoo') {  languagesList.push({ name:'Lao',  id:'lao_Laoo', }); } 
      else if (lang =='lij_Latn') {  languagesList.push({ name:'Ligurian',  id:'lij_Latn', }); } 
      else if (lang =='lim_Latn') {  languagesList.push({ name:'Limburgish',  id:'lim_Latn', }); } 
      else if (lang =='lin_Latn') {  languagesList.push({ name:'Lingala',  id:'lin_Latn', }); } 
      else if (lang =='lit_Latn') {  languagesList.push({ name:'Lithuanian',  id:'lit_Latn', }); } 
      else if (lang =='lmo_Latn') {  languagesList.push({ name:'Lombard',  id:'lmo_Latn', }); } 
      else if (lang =='ltg_Latn') {  languagesList.push({ name:'Latgalian',  id:'ltg_Latn', }); } 
      else if (lang =='ltz_Latn') {  languagesList.push({ name:'Luxembourgish',  id:'ltz_Latn', }); } 
      else if (lang =='lua_Latn') {  languagesList.push({ name:'Luba-Kasai',  id:'lua_Latn', }); } 
      else if (lang =='lug_Latn') {  languagesList.push({ name:'Ganda',  id:'lug_Latn', }); } 
      else if (lang =='luo_Latn') {  languagesList.push({ name:'Luo',  id:'luo_Latn', }); } 
      else if (lang =='lus_Latn') {  languagesList.push({ name:'Mizo',  id:'lus_Latn', }); } 
      else if (lang =='lvs_Latn') {  languagesList.push({ name:'Standard Latvian',  id:'lvs_Latn', }); } 
      else if (lang =='mag_Deva') {  languagesList.push({ name:'Magahi',  id:'mag_Deva', }); } 
      else if (lang =='mai_Deva') {  languagesList.push({ name:'Maithili',  id:'mai_Deva', }); } 
      else if (lang =='mal_Mlym') {  languagesList.push({ name:'Malayalam',  id:'mal_Mlym', }); } 
      else if (lang =='mar_Deva') {  languagesList.push({ name:'Marathi',  id:'mar_Deva', }); } 
      else if (lang =='min_Arab') {  languagesList.push({ name:'Minangkabau (Arabic script)',  id:'min_Arab', }); } 
      else if (lang =='min_Latn') {  languagesList.push({ name:'Minangkabau (Latin script)',  id:'min_Latn', }); } 
      else if (lang =='mkd_Cyrl') {  languagesList.push({ name:'Macedonian',  id:'mkd_Cyrl', }); } 
      else if (lang =='plt_Latn') {  languagesList.push({ name:'Plateau Malagasy',  id:'plt_Latn', }); } 
      else if (lang =='mlt_Latn') {  languagesList.push({ name:'Maltese',  id:'mlt_Latn', }); } 
      else if (lang =='mni_Beng') {  languagesList.push({ name:'Meitei (Bengali script)',  id:'mni_Beng', }); } 
      else if (lang =='khk_Cyrl') {  languagesList.push({ name:'Halh Mongolian',  id:'khk_Cyrl', }); } 
      else if (lang =='mos_Latn') {  languagesList.push({ name:'Mossi',  id:'mos_Latn', }); } 
      else if (lang =='mri_Latn') {  languagesList.push({ name:'Maori',  id:'mri_Latn', }); } 
      else if (lang =='mya_Mymr') {  languagesList.push({ name:'Burmese',  id:'mya_Mymr', }); } 
      else if (lang =='nld_Latn') {  languagesList.push({ name:'Dutch',  id:'nld_Latn', }); } 
      else if (lang =='nno_Latn') {  languagesList.push({ name:'Norwegian Nynorsk',  id:'nno_Latn', }); } 
      else if (lang =='nob_Latn') {  languagesList.push({ name:'Norwegian Bokmål',  id:'nob_Latn', }); } 
      else if (lang =='npi_Deva') {  languagesList.push({ name:'Nepali',  id:'npi_Deva', }); } 
      else if (lang =='nso_Latn') {  languagesList.push({ name:'Northern Sotho',  id:'nso_Latn', }); } 
      else if (lang =='nus_Latn') {  languagesList.push({ name:'Nuer',  id:'nus_Latn', }); } 
      else if (lang =='nya_Latn') {  languagesList.push({ name:'Nyanja',  id:'nya_Latn', }); } 
      else if (lang =='oci_Latn') {  languagesList.push({ name:'Occitan',  id:'oci_Latn', }); } 
      else if (lang =='gaz_Latn') {  languagesList.push({ name:'West Central Oromo',  id:'gaz_Latn', }); } 
      else if (lang =='ory_Orya') {  languagesList.push({ name:'Odia',  id:'ory_Orya', }); } 
      else if (lang =='pag_Latn') {  languagesList.push({ name:'Pangasinan',  id:'pag_Latn', }); } 
      else if (lang =='pan_Guru') {  languagesList.push({ name:'Eastern Panjabi',  id:'pan_Guru', }); } 
      else if (lang =='pap_Latn') {  languagesList.push({ name:'Papiamento',  id:'pap_Latn', }); } 
      else if (lang =='pes_Arab') {  languagesList.push({ name:'Western Persian',  id:'pes_Arab', }); } 
      else if (lang =='pol_Latn') {  languagesList.push({ name:'Polish',  id:'pol_Latn', }); } 
      else if (lang =='por_Latn') {  languagesList.push({ name:'Portuguese',  id:'por_Latn', }); } 
      else if (lang =='prs_Arab') {  languagesList.push({ name:'Dari',  id:'prs_Arab', }); } 
      else if (lang =='pbt_Arab') {  languagesList.push({ name:'Southern Pashto',  id:'pbt_Arab', }); } 
      else if (lang =='quy_Latn') {  languagesList.push({ name:'Ayacucho Quechua',  id:'quy_Latn', }); } 
      else if (lang =='ron_Latn') {  languagesList.push({ name:'Romanian',  id:'ron_Latn', }); } 
      else if (lang =='run_Latn') {  languagesList.push({ name:'Rundi',  id:'run_Latn', }); } 
      else if (lang =='rus_Cyrl') {  languagesList.push({ name:'Russian',  id:'rus_Cyrl', }); } 
      else if (lang =='sag_Latn') {  languagesList.push({ name:'Sango',  id:'sag_Latn', }); } 
      else if (lang =='san_Deva') {  languagesList.push({ name:'Sanskrit',  id:'san_Deva', }); } 
      else if (lang =='sat_Olck') {  languagesList.push({ name:'Santali',  id:'sat_Olck', }); } //
      else if (lang =='scn_Latn') {  languagesList.push({ name:'Sicilian',  id:'scn_Latn', }); } 
      else if (lang =='shn_Mymr') {  languagesList.push({ name:'Shan',  id:'shn_Mymr', }); } 
      else if (lang =='sin_Sinh') {  languagesList.push({ name:'Sinhala',  id:'sin_Sinh', }); } 
      else if (lang =='slk_Latn') {  languagesList.push({ name:'Slovak',  id:'slk_Latn', }); } 
      else if (lang =='slv_Latn') {  languagesList.push({ name:'Slovenian',  id:'slv_Latn', }); } 
      else if (lang =='smo_Latn') {  languagesList.push({ name:'Samoan',  id:'smo_Latn', }); } 
      else if (lang =='sna_Latn') {  languagesList.push({ name:'Shona',  id:'sna_Latn', }); } 
      else if (lang =='snd_Arab') {  languagesList.push({ name:'Sindhi',  id:'snd_Arab', }); } 
      else if (lang =='som_Latn') {  languagesList.push({ name:'Somali',  id:'som_Latn', }); } 
      else if (lang =='sot_Latn') {  languagesList.push({ name:'Southern Sotho',  id:'sot_Latn', }); } 
      else if (lang =='spa_Latn') {  languagesList.push({ name:'Spanish',  id:'spa_Latn', }); } 
      else if (lang =='als_Latn') {  languagesList.push({ name:'Tosk Albanian',  id:'als_Latn', }); } 
      else if (lang =='srd_Latn') {  languagesList.push({ name:'Sardinian',  id:'srd_Latn', }); } 
      else if (lang =='srp_Cyrl') {  languagesList.push({ name:'Serbian',  id:'srp_Cyrl', }); } 
      else if (lang =='ssw_Latn') {  languagesList.push({ name:'Swati',  id:'ssw_Latn', }); } 
      else if (lang =='sun_Latn') {  languagesList.push({ name:'Sundanese',  id:'sun_Latn', }); } 
      else if (lang =='swe_Latn') {  languagesList.push({ name:'Swedish',  id:'swe_Latn', }); } 
      else if (lang =='swh_Latn') {  languagesList.push({ name:'Swahili',  id:'swh_Latn', }); } 
      else if (lang =='szl_Latn') {  languagesList.push({ name:'Silesian',  id:'szl_Latn', }); } 
      else if (lang =='tam_Taml') {  languagesList.push({ name:'Tamil',  id:'tam_Taml', }); } 
      else if (lang =='tat_Cyrl') {  languagesList.push({ name:'Tatar',  id:'tat_Cyrl', }); } 
      else if (lang =='tel_Telu') {  languagesList.push({ name:'Telugu',  id:'tel_Telu', }); } 
      else if (lang =='tgk_Cyrl') {  languagesList.push({ name:'Tajik',  id:'tgk_Cyrl', }); } 
      else if (lang =='tgl_Latn') {  languagesList.push({ name:'Tagalog',  id:'tgl_Latn', }); } 
      else if (lang =='tha_Thai') {  languagesList.push({ name:'Thai',  id:'tha_Thai', }); } 
      else if (lang =='tir_Ethi') {  languagesList.push({ name:'Tigrinya',  id:'tir_Ethi', }); } 
      else if (lang =='taq_Latn') {  languagesList.push({ name:'Tamasheq (Latin script)',  id:'taq_Latn', }); } 
      else if (lang =='taq_Tfng') {  languagesList.push({ name:'Tamasheq (Tifinagh script)',  id:'taq_Tfng', }); } 
      else if (lang =='tpi_Latn') {  languagesList.push({ name:'Tok Pisin',  id:'tpi_Latn', }); } 
      else if (lang =='tsn_Latn') {  languagesList.push({ name:'Tswana',  id:'tsn_Latn', }); } 
      else if (lang =='tso_Latn') {  languagesList.push({ name:'Tsonga',  id:'tso_Latn', }); } 
      else if (lang =='tuk_Latn') {  languagesList.push({ name:'Turkmen',  id:'tuk_Latn', }); } 
      else if (lang =='tum_Latn') {  languagesList.push({ name:'Tumbuka',  id:'tum_Latn', }); } 
      else if (lang =='tur_Latn') {  languagesList.push({ name:'Turkish',  id:'tur_Latn', }); } 
      else if (lang =='twi_Latn') {  languagesList.push({ name:'Twi',  id:'twi_Latn', }); } 
      else if (lang =='tzm_Tfng') {  languagesList.push({ name:'Central Atlas Tamazight',  id:'tzm_Tfng', }); } 
      else if (lang =='uig_Arab') {  languagesList.push({ name:'Uyghur',  id:'uig_Arab', }); } 
      else if (lang =='ukr_Cyrl') {  languagesList.push({ name:'Ukrainian',  id:'ukr_Cyrl', }); } 
      else if (lang =='umb_Latn') {  languagesList.push({ name:'Umbundu',  id:'umb_Latn', }); } 
      else if (lang =='urd_Arab') {  languagesList.push({ name:'Urdu',  id:'urd_Arab', }); } 
      else if (lang =='uzn_Latn') {  languagesList.push({ name:'Northern Uzbek',  id:'uzn_Latn', }); } 
      else if (lang =='vec_Latn') {  languagesList.push({ name:'Venetian',  id:'vec_Latn', }); } 
      else if (lang =='vie_Latn') {  languagesList.push({ name:'Vietnamese',  id:'vie_Latn', }); } 
      else if (lang =='war_Latn') {  languagesList.push({ name:'Waray',  id:'war_Latn', }); } 
      else if (lang =='wol_Latn') {  languagesList.push({ name:'Wolof',  id:'wol_Latn', }); } 
      else if (lang =='xho_Latn') {  languagesList.push({ name:'Xhosa',  id:'xho_Latn', }); } 
      else if (lang =='ydd_Hebr') {  languagesList.push({ name:'Eastern Yiddish',  id:'ydd_Hebr', }); } 
      else if (lang =='yor_Latn') {  languagesList.push({ name:'Yoruba',  id:'yor_Latn', }); } 
      else if (lang =='yue_Hant') {  languagesList.push({ name:'Yue Chinese',  id:'yue_Hant', }); } 
      else if (lang =='zho_Hans') {  languagesList.push({ name:'Chinese (Simplified)',  id:'zho_Hans', }); } 
      else if (lang =='zho_Hant') {  languagesList.push({ name:'Chinese (Traditional)',  id:'zho_Hant', }); } 
      else if (lang =='zsm_Latn') {  languagesList.push({ name:'Standard Malay',  id:'zsm_Latn', }); } 
      else if (lang =='zul_Latn') {  languagesList.push({ name:'Zulu',  id:'zul_Latn'}); }

    })
    return languagesList
  }


  const  resizeSelect=(sel) => {
    let tempOption = document.createElement('option');
    tempOption.textContent = sel.selectedOptions[0].textContent;
    let tempSelect = document.createElement('select');
    tempSelect.style.visibility = "hidden";
    tempSelect.style.position = "fixed"
    tempSelect.appendChild(tempOption);
    sel.after(tempSelect);
    if(tempOption.textContent.length >10)
    sel.style.width = `${+tempSelect.clientWidth + 100}px`;
    if(tempOption.textContent.length <10)
    sel.style.width = `${+tempSelect.clientWidth + 100}px`;
    tempSelect.remove();
  }

 

  useEffect(async () => {

    const guestUser = await axios.get(`${API_BASE_URL}/user/guest`);
    if (state && state.user) {

      if (state.availableLanguages.length > 0) {
        setLanguages(getlanguages(state.availableLanguages));
        setSrcLang(languagesList[0]);
        setDestLang(languagesList[1]);
      }
      // axios
      //   .get(`${API_BASE_URL}/user/${state.user._id}`, {
      //     headers: {
      //       Authorization: `Bearer ${state.user.token}`,
      //     },
      //   })
        // .then(async (res) => {
        //   if (!res.data.user)
        //     localStorage.removeItem("poshTranslateState");
          // dispatch({
          //   type: "UPDATE_PURCHASE_DATE",
          //   payload: res.data.user?.plan_purchased_on
          // });
          // const { data } = await axios.get(`${API_BASE_URL}/plan/${res.data.user.planId}`);

          // var d1 = Date.parse(new Date(res.data.user?.plan_purchased_on).toISOString().slice(0, 10));
          // var d2 = Date.parse(new Date().toISOString().slice(0, 10));

          // if (d1 == d2) {
            // alert("Your plan is expired")
            // navigate("/dashboard");
          // }
        // })
        // .catch(() => { });


    }
    else {

      if (guestUser && guestUser.data.user.role == "guest") {
        setLanguages(getlanguages(guestUser.data.availableLanguages));
        setSrcLang(languagesList[0]);
        setDestLang(languagesList[1]);
      }
    }
    socket.on('connect', () => {
      // console.log("I am connected")
      // setIsConnected(true);
    });
    socket.on('disconnect', () => {
      // console.log("I am disconnected")
      // setIsConnected(false);
    });
    socket.on('getDetectData', (data) => {
      console.log('getDetectData', data);
      if ( data.fileType == 3) {
        setFileProcessed(true)
        
      }
      else if (data.remarks == "extracting file data" || data.remarks == "translating content" || data.remarks == "handling tables" || data.remarks == "writing the translated document") {
        // setErrorInProcessing(true);
        if(data.remarks == "extracting file data")
        setChangePercentage(25);
        if(data.remarks == "working")
        setChangePercentage(35);
        else if(data.remarks == "translating content")
        setChangePercentage(50);
        else if(data.remarks == "handling tables")
        setChangePercentage(75);
        else if(data.remarks == "writing the translated document")
        setChangePercentage(100);
        setErrorMsg(data.remarks)
      }
      else if (data.remarks) {
        setErrorInProcessing(true);
        setErrorMsg(data.remarks)
      }

      else
        setFileProcessed(true)
    });
    return () => {
      socket.off('connect');
      socket.off('disconnect');
    };

  }, []);




  useEffect(() => {
    if (props && props?.language) {
      setAppLanguage(props.language)
    }

  }, [props])

  const switchLanguages = () => {
    var srcLanguage = srcLang;
    console.log(
      "srcLanguage",destLang, srcLanguage
    )
    setSwitchLang(!switchLang)
    setSrcLang(destLang);
    setDestLang(srcLanguage);
  }
  return (
    <InjectIntl>
      {({ intl }) => (

        (state?.direct_translation?.value == "yes" || state?.document_translation?.value == "yes" || state?.image_translation?.value == "yes") ?
          <div className="max-w-[1090px] w-[100%] mx-auto mt-10 h-1/2">

            <div className="maincontainer">
              <div className="container ">
                <div className="row">
                  <div className="col-lg-12 mx-auto">
                    <div className="flex flex-col items-center  pt-1 mx-auto rounded-xl bg-white translation"
                      style={{ direction: languageSelected == 'ar'? 'rtl': 'ltr'}}>

                      <div className="flex flex-row cursor-pointer items-center text-center mx-auto mt-2">
                        {state?.direct_translation?.value == "yes" ?
                          <button style={{ marginLeft: '0.2rem'}}
                            onClick={() => { setMode(0); setFileProcessed(false); setErrorInProcessing(false); setExtractedText('') }}
                            className={`${mode === 0
                              ? "bg-[#048A8F] text-white outline-dark btnsubheadding"
                              : "bg-[#FFFFFF] hover:bg-[#FFFFFF] text-[#363636] outline-dark btnsubheadding"
                              } flex flex-row items-center text-center py-4 px-2 my-4`}
                          >

                            <div className="flex flex-col items-center ml-2 mr-2 btnsubheadding "
                            >
                              <div>{intl.formatMessage({ id: 'home.textTranslate'})}</div>
                              <div className={`${mode === 0
                                ? "btnheadding sm:block  "

                                : "btnheadding sm:block  text-[#727272] "} `}
                              >
                                {languages.length} {intl.formatMessage({ id: 'home.languages'})}
                              </div>
                            </div>
                          </button> : <>&nbsp;&nbsp;&nbsp;&nbsp;</>}
                        {state?.document_translation?.value == "yes" ?
                          <button style={{ marginLeft: '0.5rem', marginRight: '0.5rem'}}

                            onClick={() => { setMode(1); setFileProcessed(false); setErrorInProcessing(false); setExtractedText('') }}
                            className={`${mode === 1
                              ? "bg-[#048A8F] text-white outline-dark btnsubheadding"
                              : "bg-[#FFFFFF] hover:bg-[#FFFFFF] text-[#363636] outline-dark btnsubheadding"
                              } flex flex-row items-center text-center py-4 px-2 my-4`}
                          >

                            <div className="flex flex-col items-center  content-center ml-2 mr-2 btnsubheadding"

                            >

                              <div >{intl.formatMessage({ id: 'home.textDocument'})}</div>
                              <div className={`${mode === 1
                                ? "btnheadding sm:block  "

                                : "btnheadding sm:block  text-[#727272] "} `}
                              >


                                {intl.formatMessage({ id: 'home.textwordpdf'})}</div>

                            </div>
                          </button> : <>&nbsp;&nbsp;&nbsp;&nbsp;</>}
                        {
                          state?.image_translation?.value == "yes" &&
                          <button style={{ marginRight: '0.2rem'}}
                            onClick={() => { setMode(2); setFileProcessed(false); setErrorInProcessing(false); setExtractedText('') }}
                            className={`${mode === 2
                              ? "bg-[#048A8F] text-white outline-dark btnsubheadding"
                              : "bg-[#FFFFFF] hover:bg-[#FFFFFF] text-[#363636]  content-center outline-dark btnsubheadding"
                              } flex flex-row items-center text-center py-4 px-2 my-4`}
                          >

                            <div className="flex flex-col items-center ml-2 mr-2 btnsubheadding" >
                              <div>{intl.formatMessage({ id: 'home.imageTranslate'})}</div>
                              <div className={`${mode === 2
                                ? "btnheadding sm:block  "

                                : "btnheadding sm:block text-[#727272] "} `}
                              >

                                {intl.formatMessage({ id: 'home.jpgjpegpng'})}</div>


                            </div>
                          </button>
                        }

                      </div>

                      <div className="my-3 flex flex-row cursor-pointer items-center mx-auto">

                        {

                          <>
                            <select
                              value={srcLang?.name}
                              onChange={(e) => {
                                if (e.target.value == "Auto Detect")
                                  setSrcLang(e.target.value);
                                else
                                  setSrcLang(languages.find((l) => l.name === e.target.value));
                              }

                              }
                              className="lang-outline max-auto px-1 py-2 border-[1px] rounded-4-md cursor-pointer text-[#363636] select"
                            >
                              <option >Auto Detect </option>
                              {languages.map((lang) => (
                                <option key={lang?.id}>{lang?.name}</option>
                              ))}
                            </select>

                            <TbArrowsRightLeft
                              onClick={switchLanguages}
                              size={45}
                              style={{ color: '#5CB0B4'}}
                              className="mx-5 cursor-pointer "
                            />

                            <select
                              value={destLang?.name}
                              onChange={(e) => {
                                setDestLang(languages.find((l) => l.name === e.target.value));
                                setUpdateLang(true)
                              }
                              }
                              className="lang-outline max-auto px-1 py-2 border-[1px] rounded-4-md cursor-pointer text-[#363636] select"
                            >

                              {languages
                                .map((lang) => (
                                  <option key={lang?.id}>{lang?.name}</option>
                                ))}
                            </select>

                          </>
                        }
                      </div>



                      {mode === 0 ? (
                        <TextTranslation srcLang={srcLang} destLang={destLang} intl={intl} languageSelected={props.language} switchLang={switchLang} updateLang={updateLang} />
                      ) : mode === 1 ? (
                        <DocumentTranslation errorMsg={errorMsg} changePercentage={changePercentage} socket={socket} errorInProcessing={errorInProcessing} setErrorInProcessing={setErrorInProcessing} fileProcessed={fileProcessed} setFileProcessed={setFileProcessed} srcLang={srcLang} destLang={destLang} intl={intl} languageSelected={props.language} />
                      ) : (
                        <ImageTranslation extractedText={extractedText} errorMsg={errorMsg} socket={socket} errorInProcessing={errorInProcessing} setErrorInProcessing={setErrorInProcessing} fileProcessed={fileProcessed} setFileProcessed={setFileProcessed} srcLang={srcLang} destLang={destLang} intl={intl} languageSelected={props.language} />
                      )}




                    </div>
                  </div>
                </div>
              </div></div>

          </div>
          :
          <div className="max-w-[980px] w-[100%] mx-auto mt-10">

            <div className="maincontainer">
              <div className="container ">
                <div className="row">
                  <div className="col-lg-12 mx-auto">
                    <div className="flex flex-col items-center  pt-1 mx-auto rounded-xl bg-white translation"
                      style={{ direction: languageSelected == 'ar'? 'rtl': 'ltr'}}>You do not Permissions for translation </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



      )}
    </InjectIntl>
  );
};

export default Translation;
